.Greet {
        display: inline-block;
        margin-left: 10%;
        font-size: 2em;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        transform-style: preserve-3d;
}

.flip {
        animation-name: flip;
}

@keyframes flip {
        0% {
                transform: rotateX(0deg);
                opacity: 1;
        }

        40% {
                transform: rotateX(90deg);
                opacity: 0;
        }

        60% {
                transform: rotateX(90deg);
                opacity: 0;
        }

        100% {
                transform: rotateX(0deg);
                opacity: 1;
        }
}