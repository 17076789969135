.photo-gallery {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* 2 columns by default for small devices */
        gap: 0;
        /* No gaps between images */
        padding: 0;
}

.photo-gallery-item {
        overflow: hidden;
}

.photo-gallery-item img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* Ensures that the images maintain aspect ratio and fill the area */
        transition: transform 0.3s ease;
}

.photo-gallery-item img:hover {
        transform: scale(1.05);
}

/* For large screens, increase the size of the grid items */
@media (min-width: 1024px) {
        .photo-gallery {
                grid-template-columns: repeat(3, 1fr);
                /* Create 3 columns on large screens */
        }

        .photo-gallery-item {
                height: 300px;
                /* Increase height of images on large screens */
        }
}